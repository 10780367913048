import { BeaconError } from '..';
import { BeaconErrorType } from '@airgap/beacon-types';
/**
 * @category Error
 */
export class UnknownBeaconError extends BeaconError {
    constructor() {
        super(BeaconErrorType.UNKNOWN_ERROR, 'An unknown error occured. Please try again or report it to a developer.');
        this.name = 'UnknownBeaconError';
        this.title = 'Error';
    }
}
