/**
 * Geographic region where a beacon node is located. This list can be changed in the future to be more specific, but for now it should cover most general areas.
 */
export var Regions;
(function (Regions) {
    Regions["EUROPE_EAST"] = "europe-east";
    Regions["EUROPE_WEST"] = "europe-west";
    Regions["NORTH_AMERICA_EAST"] = "north-america-east";
    Regions["NORTH_AMERICA_WEST"] = "north-america-west";
    Regions["CENTRAL_AMERICA"] = "central-america";
    Regions["SOUTH_AMERICA"] = "south-america";
    Regions["ASIA_EAST"] = "asia-east";
    Regions["ASIA_WEST"] = "asia-west";
    Regions["AFRICA"] = "africa";
    Regions["AUSTRALIA"] = "australia";
})(Regions || (Regions = {}));
