var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BeaconMessageType } from '@airgap/beacon-types';
import { Logger } from '@airgap/beacon-core';
const logger = new Logger('IncomingRequestInterceptor');
/**
 * @internalapi
 *
 * The IncomingRequestInterceptor is used in the WalletClient to intercept an incoming request and enrich it with data, like app metadata.
 */
export class IncomingRequestInterceptor {
    /**
     * The method that is called during the interception
     *
     * @param config
     */
    static intercept(config) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log('INTERCEPTING REQUEST', config.message);
            if (config.message.version === '2') {
                IncomingRequestInterceptor.handleV2Message(config);
            }
            else if (config.message.version === '3') {
                IncomingRequestInterceptor.handleV3Message(config);
            }
        });
    }
    static getAppMetadata(appMetadataManager, senderId) {
        return __awaiter(this, void 0, void 0, function* () {
            const appMetadata = yield appMetadataManager.getAppMetadata(senderId);
            if (!appMetadata) {
                throw new Error('AppMetadata not found');
            }
            return appMetadata;
        });
    }
    static handleV2Message(config) {
        return __awaiter(this, void 0, void 0, function* () {
            const { message, connectionInfo, appMetadataManager, interceptorCallback } = config;
            switch (message.type) {
                case BeaconMessageType.PermissionRequest:
                    {
                        console.log('PERMISSION REQUEST V*', message);
                        // TODO: Remove v1 compatibility in later version
                        if (message.appMetadata.beaconId && !message.appMetadata.senderId) {
                            message.appMetadata.senderId = message.appMetadata.beaconId;
                            delete message.appMetadata.beaconId;
                        }
                        yield appMetadataManager.addAppMetadata(message.appMetadata);
                        const request = message;
                        interceptorCallback(request, connectionInfo);
                    }
                    break;
                case BeaconMessageType.OperationRequest:
                    {
                        const appMetadata = yield IncomingRequestInterceptor.getAppMetadata(appMetadataManager, message.senderId);
                        const request = Object.assign({ appMetadata }, message);
                        interceptorCallback(request, connectionInfo);
                    }
                    break;
                case BeaconMessageType.SignPayloadRequest:
                    {
                        const appMetadata = yield IncomingRequestInterceptor.getAppMetadata(appMetadataManager, message.senderId);
                        const request = Object.assign({ appMetadata }, message);
                        interceptorCallback(request, connectionInfo);
                    }
                    break;
                // TODO: ENCRYPTION
                // case BeaconMessageType.EncryptPayloadRequest:
                //   {
                //     const appMetadata: AppMetadata = await IncomingRequestInterceptor.getAppMetadata(
                //       appMetadataManager,
                //       message.senderId
                //     )
                //     const request: EncryptPayloadRequestOutput = {
                //       appMetadata,
                //       ...message
                //     }
                //     interceptorCallback(request, connectionInfo)
                //   }
                //   break
                case BeaconMessageType.BroadcastRequest:
                    {
                        const appMetadata = yield IncomingRequestInterceptor.getAppMetadata(appMetadataManager, message.senderId);
                        const request = Object.assign({ appMetadata }, message);
                        interceptorCallback(request, connectionInfo);
                    }
                    break;
                default:
                    logger.log('intercept', 'Message not handled');
                    assertNever(message);
            }
        });
    }
    static handleV3Message(config) {
        return __awaiter(this, void 0, void 0, function* () {
            const { message: msg, connectionInfo, appMetadataManager, interceptorCallback } = config;
            const wrappedMessage = msg; /* TODO: Remove any */
            const v3Message = wrappedMessage.message;
            switch (v3Message.type) {
                case BeaconMessageType.PermissionRequest:
                    {
                        yield appMetadataManager.addAppMetadata(Object.assign(Object.assign({}, v3Message.blockchainData.appMetadata), { senderId: msg.senderId })); // Make sure we use the actual senderId, not what the dApp told us
                        const request /* PermissionRequestOutput */ = wrappedMessage;
                        interceptorCallback(request, connectionInfo);
                    }
                    break;
                case BeaconMessageType.BlockchainRequest:
                    {
                        // const appMetadata: AppMetadata = await IncomingRequestInterceptor.getAppMetadata(
                        //   appMetadataManager,
                        //   msg.senderId
                        // )
                        const request /* BeaconMessageWrapper<BlockchainRequestV3<string>> */ = Object.assign({}, wrappedMessage);
                        interceptorCallback(request, connectionInfo);
                    }
                    break;
                default:
                    logger.log('intercept', 'Message not handled');
                    assertNever(v3Message);
            }
        });
    }
}
function assertNever(_message) {
    throw new Error('Function not implemented.');
}
