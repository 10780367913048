import React, { FC } from "react";
import cx from "classnames";

import { Toast } from "components/ui/Toast";
import { Header } from "components/common/Header";
import { Container } from "components/common/Container";
import { Footer } from "components/common/Footer";
import { CookieConsent } from "components/common/CookieConsent";

import s from "./BaseLayout.module.sass";
import { Heading } from "components/common/Heading";

type BaseLayoutProps = {
  headerClassName?: string;
  className?: string;
};

const alertTitle = "Please migrate to Yupana V2";
const alertDescription =
  "Medium-risk security issue potentially affecting a limited number of users has been found in original version. The details will be revealed later. Thank you for your understanding!";

const YupanaLink = "https://app.yupana.finance/";

export const BaseLayout: FC<BaseLayoutProps> = ({
  children,
  headerClassName,
  className,
}) => (
  <>
    <Header className={headerClassName} />
    <Container main className={cx(s.container, className)}>
      <a href={YupanaLink} className={s.alert}>
        <Heading className={s.alertTitle} title={alertTitle} />
        <p>{alertDescription}</p>
        <br />
        <p>
          <p>To do that:</p>
          <p className={s.alertText}>1. Repay all your borrows</p>
          <p className={s.alertText}>2. Withdraw your collaterals</p>
          <p className={s.alertText}>
            3. Switch to <span className={s.link}>app.yupana.finance</span>
          </p>
          <p className={s.alertText}>4. Deposit new collaterals</p>
          <p className={s.alertText}>5. Toggle collaterals</p>
          <p className={s.alertText}>6. Make your borrows</p>
        </p>
      </a>
      {children}
      <Toast />
    </Container>
    <Footer />
    <CookieConsent />
  </>
);
