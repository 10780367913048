var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as bs58check from 'bs58check';
import { box, generateKeyPair, openBox, openSecretBox, secretBox } from '@stablelib/nacl';
import { randomBytes } from '@stablelib/random';
import { encode } from '@stablelib/utf8';
import { hash } from '@stablelib/blake2b';
import { generateKeyPairFromSeed } from '@stablelib/ed25519';
import { convertPublicKeyToX25519, convertSecretKeyToX25519 } from '@stablelib/ed25519';
import { BLAKE2b } from '@stablelib/blake2b';
import { concat } from '@stablelib/bytes';
import { sign } from '@stablelib/ed25519';
export const secretbox_NONCEBYTES = 24; // crypto_secretbox_NONCEBYTES
export const secretbox_MACBYTES = 16; // crypto_secretbox_MACBYTES
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/**
 * Convert a value to hex
 *
 * @param value
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toHex(value) {
    return Buffer.from(value).toString('hex');
}
/**
 * Get the hex hash of a value
 *
 * @param key
 */
export function getHexHash(key) {
    return __awaiter(this, void 0, void 0, function* () {
        if (typeof key === 'string') {
            return toHex(hash(encode(key), 32));
        }
        return toHex(hash(key, 32));
    });
}
/**
 * Get a keypair from a seed
 *
 * @param seed
 */
export function getKeypairFromSeed(seed) {
    return __awaiter(this, void 0, void 0, function* () {
        return generateKeyPairFromSeed(hash(encode(seed), 32));
    });
}
/**
 * Encrypt a message with a shared key
 *
 * @param message
 * @param sharedKey
 */
export function encryptCryptoboxPayload(message, sharedKey) {
    return __awaiter(this, void 0, void 0, function* () {
        const nonce = Buffer.from(randomBytes(secretbox_NONCEBYTES));
        const combinedPayload = Buffer.concat([
            nonce,
            Buffer.from(secretBox(sharedKey, nonce, Buffer.from(message, 'utf8')))
        ]);
        return toHex(combinedPayload);
    });
}
/**
 * Decrypt a message with a shared key
 *
 * @param payload
 * @param sharedKey
 */
export function decryptCryptoboxPayload(payload, sharedKey) {
    return __awaiter(this, void 0, void 0, function* () {
        const nonce = payload.slice(0, secretbox_NONCEBYTES);
        const ciphertext = payload.slice(secretbox_NONCEBYTES);
        const openBox = openSecretBox(sharedKey, nonce, ciphertext);
        if (!openBox) {
            throw new Error('Decryption failed');
        }
        return Buffer.from(openBox).toString('utf8');
    });
}
/**
 * Encrypt a message with a public key
 *
 * @param payload
 * @param publicKey
 */
export function sealCryptobox(payload, otherPublicKey) {
    return __awaiter(this, void 0, void 0, function* () {
        const kxOtherPublicKey = convertPublicKeyToX25519(Buffer.from(otherPublicKey)); // Secret bytes to scalar bytes
        const keypair = generateKeyPair();
        const state = new BLAKE2b(24);
        const nonce = state.update(keypair.publicKey, 32).update(kxOtherPublicKey, 32).digest();
        const bytesPayload = typeof payload === 'string' ? encode(payload) : payload;
        const encryptedMessage = box(kxOtherPublicKey, keypair.secretKey, nonce, bytesPayload);
        return toHex(concat(keypair.publicKey, encryptedMessage));
    });
}
/**
 * Decrypt a message with public + private key
 *
 * @param encryptedPayload
 * @param publicKey
 * @param privateKey
 */
export function openCryptobox(encryptedPayload, publicKey, privateKey) {
    return __awaiter(this, void 0, void 0, function* () {
        const kxSelfPrivateKey = convertSecretKeyToX25519(Buffer.from(privateKey)); // Secret bytes to scalar bytes
        const kxSelfPublicKey = convertPublicKeyToX25519(Buffer.from(publicKey)); // Secret bytes to scalar bytes
        const bytesPayload = typeof encryptedPayload === 'string' ? encode(encryptedPayload) : encryptedPayload;
        const epk = bytesPayload.slice(0, 32);
        const ciphertext = bytesPayload.slice(32);
        const state = new BLAKE2b(24);
        const nonce = state.update(epk, 32).update(kxSelfPublicKey, 32).digest();
        const decryptedMessage2 = openBox(epk, kxSelfPrivateKey, nonce, ciphertext);
        if (!decryptedMessage2) {
            throw new Error('Decryption failed');
        }
        return Buffer.from(decryptedMessage2).toString();
    });
}
/**
 * Get an address from the public key
 *
 * @param publicKey
 */
export function getAddressFromPublicKey(publicKey) {
    return __awaiter(this, void 0, void 0, function* () {
        const prefixes = {
            // tz1...
            edpk: {
                length: 54,
                prefix: Buffer.from(new Uint8Array([6, 161, 159]))
            },
            // tz2...
            sppk: {
                length: 55,
                prefix: Buffer.from(new Uint8Array([6, 161, 161]))
            },
            // tz3...
            p2pk: {
                length: 55,
                prefix: Buffer.from(new Uint8Array([6, 161, 164]))
            }
        };
        let prefix;
        let plainPublicKey;
        if (publicKey.length === 64) {
            prefix = prefixes.edpk.prefix;
            plainPublicKey = publicKey;
        }
        else {
            const entries = Object.entries(prefixes);
            for (let index = 0; index < entries.length; index++) {
                const [key, value] = entries[index];
                if (publicKey.startsWith(key) && publicKey.length === value.length) {
                    prefix = value.prefix;
                    const decoded = bs58check.decode(publicKey);
                    plainPublicKey = decoded.slice(key.length, decoded.length).toString('hex');
                    break;
                }
            }
        }
        if (!prefix || !plainPublicKey) {
            throw new Error(`invalid publicKey: ${publicKey}`);
        }
        const payload = hash(Buffer.from(plainPublicKey, 'hex'), 20);
        return bs58check.encode(Buffer.concat([prefix, Buffer.from(payload)]));
    });
}
/**
 * Prefix the public key if it's not prefixed
 *
 * @param publicKey
 */
export function prefixPublicKey(publicKey) {
    return __awaiter(this, void 0, void 0, function* () {
        if (publicKey.length !== 64) {
            return publicKey;
        }
        const payload = Buffer.from(publicKey, 'hex');
        return bs58check.encode(Buffer.concat([new Uint8Array([13, 15, 37, 217]), Buffer.from(payload)]));
    });
}
/**
 * Get the recipient string used in the matrix message
 *
 * @param recipientHash
 * @param relayServer
 */
export function recipientString(recipientHash, relayServer) {
    return `@${recipientHash}:${relayServer}`;
}
const toBuffer = (message) => __awaiter(void 0, void 0, void 0, function* () {
    if (message.length % 2 !== 0) {
        return encode(message);
    }
    let adjustedMessage = message;
    if (message.startsWith('0x')) {
        adjustedMessage = message.slice(2);
    }
    const buffer = Buffer.from(adjustedMessage, 'hex');
    if (buffer.length === adjustedMessage.length / 2) {
        return buffer;
    }
    return encode(message);
});
const coinlibhash = (message, size = 32) => __awaiter(void 0, void 0, void 0, function* () {
    return hash(message, size);
});
export const signMessage = (message, keypair) => __awaiter(void 0, void 0, void 0, function* () {
    const bufferMessage = yield toBuffer(message);
    const edsigPrefix = new Uint8Array([9, 245, 205, 134, 18]);
    const hash = yield coinlibhash(bufferMessage);
    const rawSignature = sign(keypair.secretKey, hash);
    const signature = bs58check.encode(Buffer.concat([Buffer.from(edsigPrefix), Buffer.from(rawSignature)]));
    return signature;
});
/* eslint-enable prefer-arrow/prefer-arrow-functions */
