var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { sign } from '@stablelib/ed25519';
import axios from 'axios';
import { getHexHash, toHex, recipientString, openCryptobox, encryptCryptoboxPayload, decryptCryptoboxPayload, secretbox_NONCEBYTES, secretbox_MACBYTES } from '@airgap/beacon-utils';
import { MatrixClient } from '../matrix-client/MatrixClient';
import { MatrixClientEventType } from '../matrix-client/models/MatrixClientEvent';
import { MatrixMessageType } from '../matrix-client/models/MatrixMessage';
import { StorageKey, Regions } from '@airgap/beacon-types';
import { PeerManager, BEACON_VERSION, getSenderId, Logger, CommunicationClient } from '@airgap/beacon-core';
import { ExposedPromise, generateGUID } from '@airgap/beacon-utils';
import { hash } from '@stablelib/blake2b';
import { encode } from '@stablelib/utf8';
const logger = new Logger('P2PCommunicationClient');
const REGIONS_AND_SERVERS = {
    [Regions.EUROPE_WEST]: [
        'beacon-node-1.diamond.papers.tech',
        'beacon-node-1.sky.papers.tech',
        'beacon-node-2.sky.papers.tech',
        'beacon-node-1.hope.papers.tech',
        'beacon-node-1.hope-2.papers.tech',
        'beacon-node-1.hope-3.papers.tech',
        'beacon-node-1.hope-4.papers.tech',
        'beacon-node-1.hope-5.papers.tech'
    ]
};
/**
 * @internalapi
 */
export class P2PCommunicationClient extends CommunicationClient {
    constructor(name, keyPair, replicationCount, storage, matrixNodes, iconUrl, appUrl) {
        super(keyPair);
        this.name = name;
        this.replicationCount = replicationCount;
        this.storage = storage;
        this.iconUrl = iconUrl;
        this.appUrl = appUrl;
        this.client = new ExposedPromise();
        this.activeListeners = new Map();
        this.ignoredRooms = [];
        this.loginCounter = 0;
        logger.log('constructor', 'P2PCommunicationClient created');
        this.ENABLED_RELAY_SERVERS = REGIONS_AND_SERVERS;
        if (matrixNodes) {
            this.ENABLED_RELAY_SERVERS = Object.assign(Object.assign({}, REGIONS_AND_SERVERS), matrixNodes);
        }
    }
    getPairingRequestInfo() {
        return __awaiter(this, void 0, void 0, function* () {
            const info = {
                id: yield generateGUID(),
                type: 'p2p-pairing-request',
                name: this.name,
                version: BEACON_VERSION,
                publicKey: yield this.getPublicKey(),
                relayServer: (yield this.getRelayServer()).server
            };
            if (this.iconUrl) {
                info.icon = this.iconUrl;
            }
            if (this.appUrl) {
                info.appUrl = this.appUrl;
            }
            return info;
        });
    }
    getPairingResponseInfo(request) {
        return __awaiter(this, void 0, void 0, function* () {
            const info = {
                id: request.id,
                type: 'p2p-pairing-response',
                name: this.name,
                version: request.version,
                publicKey: yield this.getPublicKey(),
                relayServer: (yield this.getRelayServer()).server
            };
            if (this.iconUrl) {
                info.icon = this.iconUrl;
            }
            if (this.appUrl) {
                info.appUrl = this.appUrl;
            }
            return info;
        });
    }
    findBestRegion() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.selectedRegion) {
                return this.selectedRegion;
            }
            const keys = Object.keys(this.ENABLED_RELAY_SERVERS);
            const allPromises = [];
            keys.forEach((key) => {
                var _a;
                const nodes = (_a = this.ENABLED_RELAY_SERVERS[key]) !== null && _a !== void 0 ? _a : [];
                if (nodes.length === 0) {
                    return;
                }
                const index = Math.floor(Math.random() * nodes.length);
                allPromises.push(this.getBeaconInfo(nodes[index])
                    .then((res) => ({
                    region: key,
                    server: nodes[index],
                    response: res
                }))
                    .catch((err) => new Promise((_resolve, reject) => {
                    // This workaround is done because Promise.race stops at the first failure, but we need the first success.
                    // TODO: If all promises have been rejected, let's not wait 2000 and abort earlier.
                    setTimeout(() => reject(err), 2000);
                })));
            });
            const region = yield Promise.race(allPromises);
            this.selectedRegion = region.region;
            return region.region;
            // Select random server from each region.
            // Start request to random server from each region
            // Fastest response wins, region is selected
        });
    }
    getRelayServer() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.relayServer) {
                const relayServer = yield this.relayServer.promise;
                // We make sure the locally cached timestamp is not older than 1 minute, if it is, we refresh it
                if (Date.now() - relayServer.localTimestamp < 60 * 1000) {
                    return { server: relayServer.server, timestamp: relayServer.timestamp };
                }
                const info = yield this.getBeaconInfo(relayServer.server);
                this.relayServer.resolve({
                    server: relayServer.server,
                    timestamp: info.timestamp,
                    localTimestamp: new Date().getTime()
                });
                return { server: relayServer.server, timestamp: info.timestamp };
            }
            else {
                this.relayServer = new ExposedPromise();
            }
            const node = yield this.storage.get(StorageKey.MATRIX_SELECTED_NODE);
            if (node && node.length > 0) {
                const info = yield this.getBeaconInfo(node);
                this.relayServer.resolve({
                    server: node,
                    timestamp: info.timestamp,
                    localTimestamp: new Date().getTime()
                });
                return { server: node, timestamp: info.timestamp };
            }
            const region = yield this.findBestRegion();
            const regionNodes = this.ENABLED_RELAY_SERVERS[region];
            if (!regionNodes) {
                throw new Error(`No servers found for region ${region}`);
            }
            const nodes = [...regionNodes];
            while (nodes.length > 0) {
                const index = Math.floor(Math.random() * nodes.length);
                const server = nodes[index];
                try {
                    const response = yield this.getBeaconInfo(server);
                    this.storage
                        .set(StorageKey.MATRIX_SELECTED_NODE, server)
                        .catch((error) => logger.log(error));
                    this.relayServer.resolve({
                        server,
                        timestamp: response.timestamp,
                        localTimestamp: new Date().getTime()
                    });
                    return { server, timestamp: response.timestamp };
                }
                catch (relayError) {
                    logger.log(`Ignoring server "${server}", trying another one...`);
                    nodes.splice(index, 1);
                }
            }
            this.relayServer.reject(`No matrix server reachable!`);
            throw new Error(`No matrix server reachable!`);
        });
    }
    getBeaconInfo(server) {
        return __awaiter(this, void 0, void 0, function* () {
            return axios
                .get(`https://${server}/_synapse/client/beacon/info`)
                .then((res) => ({
                region: res.data.region,
                known_servers: res.data.known_servers,
                timestamp: Math.floor(res.data.timestamp)
            }));
        });
    }
    tryJoinRooms(roomId, retry = 1) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield (yield this.client.promise).joinRooms(roomId);
            }
            catch (error) {
                if (retry <= 10 && error.errcode === 'M_FORBIDDEN') {
                    // If we join the room too fast after receiving the invite, the server can accidentally reject our join. This seems to be a problem only when using a federated multi-node setup. Usually waiting for a couple milliseconds solves the issue, but to handle lag, we will keep retrying for 2 seconds.
                    logger.log(`Retrying to join...`, error);
                    setTimeout(() => __awaiter(this, void 0, void 0, function* () {
                        yield this.tryJoinRooms(roomId, retry + 1);
                    }), 200);
                }
                else {
                    logger.log(`Failed to join after ${retry} tries.`, error);
                }
            }
        });
    }
    start() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            logger.log('start', 'starting client');
            logger.log('start', `connecting to server`);
            const relayServer = yield this.getRelayServer();
            const client = MatrixClient.create({
                baseUrl: `https://${relayServer.server}`,
                storage: this.storage
            });
            this.initialListener = (event) => __awaiter(this, void 0, void 0, function* () {
                if (this.initialEvent && this.initialEvent.timestamp && event && event.timestamp) {
                    if (this.initialEvent.timestamp < event.timestamp) {
                        this.initialEvent = event;
                    }
                }
                else {
                    this.initialEvent = event;
                }
            });
            client.subscribe(MatrixClientEventType.MESSAGE, this.initialListener);
            client.subscribe(MatrixClientEventType.INVITE, (event) => __awaiter(this, void 0, void 0, function* () {
                let member;
                if (event.content.members.length === 1) {
                    // If there is only one member we know it's a new room
                    // TODO: Use the "sender" of the event instead
                    member = event.content.members[0];
                }
                yield this.tryJoinRooms(event.content.roomId);
                if (member) {
                    yield this.updateRelayServer(member);
                    yield this.updatePeerRoom(member, event.content.roomId);
                }
            }));
            if (!relayServer.timestamp) {
                throw new Error('No timestamp received from relay server');
            }
            const time = Math.floor(relayServer.timestamp);
            const loginString = `login:${Math.floor(time / (5 * 60))}`;
            logger.log('start', `login ${loginString}, ${yield this.getPublicKeyHash()} on ${relayServer.server}`);
            const loginRawDigest = hash(encode(loginString), 32);
            const secretKey = (_a = this.keyPair.secretKey) !== null && _a !== void 0 ? _a : this.keyPair.privateKey;
            const rawSignature = sign(secretKey, loginRawDigest);
            try {
                yield client.start({
                    id: yield this.getPublicKeyHash(),
                    password: `ed:${toHex(rawSignature)}:${yield this.getPublicKey()}`,
                    deviceId: toHex(this.keyPair.publicKey)
                });
            }
            catch (error) {
                logger.error('start', 'Could not log in, retrying');
                yield this.reset(); // If we can't log in, let's reset
                if (!this.selectedRegion) {
                    throw new Error('No region selected.');
                }
                if (this.loginCounter <= ((_b = this.ENABLED_RELAY_SERVERS[this.selectedRegion]) !== null && _b !== void 0 ? _b : []).length) {
                    this.loginCounter++;
                    this.start();
                    return;
                }
                else {
                    logger.error('start', 'Tried to log in to every known beacon node, but no login was successful.');
                    throw new Error('Could not connect to any beacon nodes. Try again later.');
                }
            }
            logger.log('start', 'login successful, client is ready');
            this.client.resolve(client);
        });
    }
    stop() {
        return __awaiter(this, void 0, void 0, function* () {
            logger.log('stop', 'stopping client');
            if (this.client.isResolved()) {
                yield (yield this.client.promise).stop().catch((error) => logger.error(error));
            }
            yield this.reset();
        });
    }
    reset() {
        return __awaiter(this, void 0, void 0, function* () {
            logger.log('reset', 'resetting connection');
            yield this.storage.delete(StorageKey.MATRIX_PEER_ROOM_IDS).catch((error) => logger.log(error));
            yield this.storage.delete(StorageKey.MATRIX_PRESERVED_STATE).catch((error) => logger.log(error));
            yield this.storage.delete(StorageKey.MATRIX_SELECTED_NODE).catch((error) => logger.log(error));
            // Instead of resetting everything, maybe we should make sure a new instance is created?
            this.relayServer = undefined;
            this.client = new ExposedPromise();
            this.initialEvent = undefined;
            this.initialListener = undefined;
        });
    }
    listenForEncryptedMessage(senderPublicKey, messageCallback) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.activeListeners.has(senderPublicKey)) {
                return;
            }
            logger.log('listenForEncryptedMessage', `start listening for encrypted messages from publicKey ${senderPublicKey}`);
            const sharedKey = yield this.createCryptoBoxServer(senderPublicKey, this.keyPair);
            const callbackFunction = (event) => __awaiter(this, void 0, void 0, function* () {
                if (this.isTextMessage(event.content) && (yield this.isSender(event, senderPublicKey))) {
                    let payload;
                    yield this.updateRelayServer(event.content.message.sender);
                    yield this.updatePeerRoom(event.content.message.sender, event.content.roomId);
                    try {
                        payload = Buffer.from(event.content.message.content, 'hex');
                        // content can be non-hex if it's a connection open request
                    }
                    catch (_a) {
                        /* */
                    }
                    if (payload && payload.length >= secretbox_NONCEBYTES + secretbox_MACBYTES) {
                        try {
                            const decryptedMessage = yield decryptCryptoboxPayload(payload, sharedKey.receive);
                            logger.log('listenForEncryptedMessage', `received a message from ${senderPublicKey}`, decryptedMessage);
                            // logger.log(
                            //   'listenForEncryptedMessage',
                            //   'encrypted message received',
                            //   decryptedMessage,
                            //   await new Serializer().deserialize(decryptedMessage)
                            // )
                            // console.log('calculated sender ID', await getSenderId(senderPublicKey))
                            // TODO: Add check for correct decryption key / sender ID
                            messageCallback(decryptedMessage);
                        }
                        catch (decryptionError) {
                            /* NO-OP. We try to decode every message, but some might not be addressed to us. */
                        }
                    }
                }
            });
            this.activeListeners.set(senderPublicKey, callbackFunction);
            (yield this.client.promise).subscribe(MatrixClientEventType.MESSAGE, callbackFunction);
            const lastEvent = this.initialEvent;
            if (lastEvent &&
                lastEvent.timestamp &&
                new Date().getTime() - lastEvent.timestamp < 5 * 60 * 1000) {
                logger.log('listenForEncryptedMessage', 'Handling previous event');
                yield callbackFunction(lastEvent);
            }
            else {
                logger.log('listenForEncryptedMessage', 'No previous event found');
            }
            const initialListener = this.initialListener;
            if (initialListener) {
                ;
                (yield this.client.promise).unsubscribe(MatrixClientEventType.MESSAGE, initialListener);
            }
            this.initialListener = undefined;
            this.initialEvent = undefined;
        });
    }
    unsubscribeFromEncryptedMessage(senderPublicKey) {
        return __awaiter(this, void 0, void 0, function* () {
            const listener = this.activeListeners.get(senderPublicKey);
            if (!listener) {
                return;
            }
            ;
            (yield this.client.promise).unsubscribe(MatrixClientEventType.MESSAGE, listener);
            this.activeListeners.delete(senderPublicKey);
        });
    }
    unsubscribeFromEncryptedMessages() {
        return __awaiter(this, void 0, void 0, function* () {
            ;
            (yield this.client.promise).unsubscribeAll(MatrixClientEventType.MESSAGE);
            this.activeListeners.clear();
        });
    }
    sendMessage(message, peer) {
        return __awaiter(this, void 0, void 0, function* () {
            const sharedKey = yield this.createCryptoBoxClient(peer.publicKey, this.keyPair);
            const recipientHash = yield getHexHash(Buffer.from(peer.publicKey, 'hex'));
            const recipient = recipientString(recipientHash, peer.relayServer);
            const roomId = yield this.getRelevantRoom(recipient);
            // Before we send the message, we have to wait for the join to be accepted.
            yield this.waitForJoin(roomId); // TODO: This can probably be removed because we are now waiting inside the get room method
            const encryptedMessage = yield encryptCryptoboxPayload(message, sharedKey.send);
            logger.log('sendMessage', 'sending encrypted message', peer.publicKey, roomId, message);
            (yield this.client.promise).sendTextMessage(roomId, encryptedMessage).catch((error) => __awaiter(this, void 0, void 0, function* () {
                if (error.errcode === 'M_FORBIDDEN') {
                    // Room doesn't exist
                    logger.log(`sendMessage`, `M_FORBIDDEN`, roomId, error);
                    yield this.deleteRoomIdFromRooms(roomId);
                    const newRoomId = yield this.getRelevantRoom(recipient);
                    logger.log(`sendMessage`, `Old room deleted, new room created`, newRoomId);
                    (yield this.client.promise)
                        .sendTextMessage(newRoomId, encryptedMessage)
                        .catch((error2) => __awaiter(this, void 0, void 0, function* () {
                        logger.log(`sendMessage`, `inner error`, newRoomId, error2);
                    }));
                }
                else {
                    logger.log(`sendMessage`, `unexpected error`, error);
                }
            }));
        });
    }
    updatePeerRoom(sender, roomId) {
        return __awaiter(this, void 0, void 0, function* () {
            logger.log(`updatePeerRoom`, sender, roomId);
            // Sender is in the format "@pubkeyhash:relayserver.tld"
            const split = sender.split(':');
            if (split.length < 2 || !split[0].startsWith('@')) {
                throw new Error('Invalid sender');
            }
            const roomIds = yield this.storage.get(StorageKey.MATRIX_PEER_ROOM_IDS);
            const room = roomIds[sender];
            if (room === roomId) {
                logger.debug(`updatePeerRoom`, `rooms are the same, not updating`);
            }
            logger.debug(`updatePeerRoom`, `current room`, room, 'new room', roomId);
            if (room && room[1]) {
                // If we have a room already, let's ignore it. We need to do this, otherwise it will be loaded from the matrix cache.
                logger.log(`updatePeerRoom`, `adding room "${room[1]}" to ignored array`);
                this.ignoredRooms.push(room[1]);
            }
            roomIds[sender] = roomId;
            yield this.storage.set(StorageKey.MATRIX_PEER_ROOM_IDS, roomIds);
            // TODO: We also need to delete the room from the sync state
            // If we need to delete a room, we can assume the local state is not up to date anymore, so we can reset the state
        });
    }
    deleteRoomIdFromRooms(roomId) {
        return __awaiter(this, void 0, void 0, function* () {
            const roomIds = yield this.storage.get(StorageKey.MATRIX_PEER_ROOM_IDS);
            const newRoomIds = Object.entries(roomIds)
                .filter((entry) => entry[1] !== roomId)
                .reduce((pv, cv) => (Object.assign(Object.assign({}, pv), { [cv[0]]: cv[1] })), {});
            yield this.storage.set(StorageKey.MATRIX_PEER_ROOM_IDS, newRoomIds);
            // TODO: We also need to delete the room from the sync state
            // If we need to delete a room, we can assume the local state is not up to date anymore, so we can reset the state
            this.ignoredRooms.push(roomId);
        });
    }
    listenForChannelOpening(messageCallback) {
        return __awaiter(this, void 0, void 0, function* () {
            logger.debug(`listenForChannelOpening`);
            (yield this.client.promise).subscribe(MatrixClientEventType.MESSAGE, (event) => __awaiter(this, void 0, void 0, function* () {
                if (this.isTextMessage(event.content) && (yield this.isChannelOpenMessage(event.content))) {
                    logger.log(`listenForChannelOpening`, `channel opening received, trying to decrypt`, JSON.stringify(event));
                    yield this.updateRelayServer(event.content.message.sender);
                    yield this.updatePeerRoom(event.content.message.sender, event.content.roomId);
                    const splits = event.content.message.content.split(':');
                    const payload = Buffer.from(splits[splits.length - 1], 'hex');
                    if (payload.length >= secretbox_NONCEBYTES + secretbox_MACBYTES) {
                        try {
                            const pairingResponse = JSON.parse(yield openCryptobox(payload, this.keyPair.publicKey, this.keyPair.secretKey));
                            logger.log(`listenForChannelOpening`, `channel opening received and decrypted`, JSON.stringify(pairingResponse));
                            messageCallback(Object.assign(Object.assign({}, pairingResponse), { senderId: yield getSenderId(pairingResponse.publicKey) }));
                        }
                        catch (decryptionError) {
                            /* NO-OP. We try to decode every message, but some might not be addressed to us. */
                        }
                    }
                }
            }));
        });
    }
    waitForJoin(roomId, retry = 0) {
        return __awaiter(this, void 0, void 0, function* () {
            // Rooms are updated as new events come in. `client.getRoomById` only accesses memory, it does not do any network requests.
            // TODO: Improve to listen to "JOIN" event
            const room = yield (yield this.client.promise).getRoomById(roomId);
            logger.log(`waitForJoin`, `Currently ${room.members.length} members, we need at least 2`);
            if (room.members.length >= 2 || room.members.length === 0) {
                // 0 means it's an unknown room, we don't need to wait
                return;
            }
            else {
                if (retry <= 200) {
                    // On mobile, due to app switching, we potentially have to wait for a long time
                    logger.log(`Waiting for join... Try: ${retry}`);
                    return new Promise((resolve) => {
                        setTimeout(() => __awaiter(this, void 0, void 0, function* () {
                            resolve(this.waitForJoin(roomId, retry + 1));
                        }), 100 * (retry > 50 ? 10 : 1)); // After the initial 5 seconds, retry only once per second
                    });
                }
                else {
                    throw new Error(`No one joined after ${retry} tries.`);
                }
            }
        });
    }
    sendPairingResponse(pairingRequest) {
        return __awaiter(this, void 0, void 0, function* () {
            logger.log(`sendPairingResponse`);
            const recipientHash = yield getHexHash(Buffer.from(pairingRequest.publicKey, 'hex'));
            const recipient = recipientString(recipientHash, pairingRequest.relayServer);
            // We force room creation here because if we "re-pair", we need to make sure that we don't send it to an old room.
            const roomId = yield (yield this.client.promise).createTrustedPrivateRoom(recipient);
            logger.debug(`sendPairingResponse`, `Connecting to room "${roomId}"`);
            yield this.updatePeerRoom(recipient, roomId);
            // Before we send the message, we have to wait for the join to be accepted.
            yield this.waitForJoin(roomId); // TODO: This can probably be removed because we are now waiting inside the get room method
            logger.debug(`sendPairingResponse`, `Successfully joined room.`);
            // TODO: remove v1 backwards-compatibility
            const message = typeof pairingRequest.version === 'undefined'
                ? yield this.getPublicKey() // v1
                : JSON.stringify(yield this.getPairingResponseInfo(pairingRequest)); // v2
            logger.debug(`sendPairingResponse`, `Sending pairing response`, message);
            const encryptedMessage = yield this.encryptMessageAsymmetric(pairingRequest.publicKey, message);
            const msg = ['@channel-open', recipient, encryptedMessage].join(':');
            (yield this.client.promise).sendTextMessage(roomId, msg).catch((error) => __awaiter(this, void 0, void 0, function* () {
                if (error.errcode === 'M_FORBIDDEN') {
                    // Room doesn't exist
                    logger.log(`sendPairingResponse`, `M_FORBIDDEN`, roomId, error);
                    yield this.deleteRoomIdFromRooms(roomId);
                    const newRoomId = yield this.getRelevantRoom(recipient);
                    logger.log(`sendPairingResponse`, `Old room deleted, new room created`, newRoomId);
                    (yield this.client.promise).sendTextMessage(newRoomId, msg).catch((error2) => __awaiter(this, void 0, void 0, function* () {
                        logger.log(`sendPairingResponse`, `inner error`, newRoomId, error2);
                    }));
                }
                else {
                    logger.log(`sendPairingResponse`, `unexpected error`, error);
                }
            }));
        });
    }
    isTextMessage(content) {
        return content.message.type === MatrixMessageType.TEXT;
    }
    updateRelayServer(sender) {
        return __awaiter(this, void 0, void 0, function* () {
            logger.log(`updateRelayServer`, sender);
            // Sender is in the format "@pubkeyhash:relayserver.tld"
            const split = sender.split(':');
            if (split.length < 2 || !split[0].startsWith('@')) {
                throw new Error('Invalid sender');
            }
            const senderHash = split.shift();
            const relayServer = split.join(':');
            const manager = localStorage.getItem('beacon:communication-peers-dapp')
                ? new PeerManager(this.storage, StorageKey.TRANSPORT_P2P_PEERS_DAPP)
                : new PeerManager(this.storage, StorageKey.TRANSPORT_P2P_PEERS_WALLET);
            const peers = yield manager.getPeers();
            const promiseArray = peers.map((peer) => __awaiter(this, void 0, void 0, function* () {
                const hash = `@${yield getHexHash(Buffer.from(peer.publicKey, 'hex'))}`;
                if (hash === senderHash) {
                    if (peer.relayServer !== relayServer) {
                        peer.relayServer = relayServer;
                        yield manager.addPeer(peer);
                    }
                }
            }));
            yield Promise.all(promiseArray);
        });
    }
    isChannelOpenMessage(content) {
        return __awaiter(this, void 0, void 0, function* () {
            return content.message.content.startsWith(`@channel-open:@${yield getHexHash(Buffer.from(yield this.getPublicKey(), 'hex'))}`);
        });
    }
    isSender(event, senderPublicKey) {
        return __awaiter(this, void 0, void 0, function* () {
            return event.content.message.sender.startsWith(`@${yield getHexHash(Buffer.from(senderPublicKey, 'hex'))}`);
        });
    }
    getRelevantRoom(recipient) {
        return __awaiter(this, void 0, void 0, function* () {
            const roomIds = yield this.storage.get(StorageKey.MATRIX_PEER_ROOM_IDS);
            let roomId = roomIds[recipient];
            if (!roomId) {
                logger.log(`getRelevantRoom`, `No room found for peer ${recipient}, checking joined ones.`);
                const room = yield this.getRelevantJoinedRoom(recipient);
                roomId = room.id;
                roomIds[recipient] = room.id;
                yield this.storage.set(StorageKey.MATRIX_PEER_ROOM_IDS, roomIds);
            }
            logger.log(`getRelevantRoom`, `Using room ${roomId}`);
            return roomId;
        });
    }
    getRelevantJoinedRoom(recipient) {
        return __awaiter(this, void 0, void 0, function* () {
            const joinedRooms = yield (yield this.client.promise).joinedRooms;
            logger.log('checking joined rooms', joinedRooms, recipient);
            const relevantRooms = joinedRooms
                .filter((roomElement) => !this.ignoredRooms.some((id) => roomElement.id === id))
                .filter((roomElement) => roomElement.members.some((member) => member === recipient));
            let room;
            // We always create a new room if one has been ignored. This is because if we ignore one, we know the server state changed.
            // So we cannot trust the current sync state. This can be removed once we have a method to properly clear and refresh the sync state.
            if (relevantRooms.length === 0 || this.ignoredRooms.length > 0) {
                logger.log(`getRelevantJoinedRoom`, `no relevant rooms found, creating new one`);
                const roomId = yield (yield this.client.promise).createTrustedPrivateRoom(recipient);
                room = yield (yield this.client.promise).getRoomById(roomId);
                logger.log(`getRelevantJoinedRoom`, `waiting for other party to join room: ${room.id}`);
                yield this.waitForJoin(roomId);
                logger.log(`getRelevantJoinedRoom`, `new room created and peer invited: ${room.id}`);
            }
            else {
                room = relevantRooms[0];
                logger.log(`getRelevantJoinedRoom`, `channel already open, reusing room ${room.id}`);
            }
            return room;
        });
    }
}
