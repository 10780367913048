var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { toHex, getHexHash, sealCryptobox } from '@airgap/beacon-utils';
import { convertPublicKeyToX25519, convertSecretKeyToX25519 } from '@stablelib/ed25519';
import { clientSessionKeys, serverSessionKeys } from '@stablelib/x25519-session';
/**
 * @internalapi
 *
 *
 */
export class CommunicationClient {
    constructor(keyPair) {
        this.keyPair = keyPair;
        this.eventHandlers = new Map();
    }
    /**
     * Get the public key
     */
    getPublicKey() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            return toHex((_a = this.keyPair) === null || _a === void 0 ? void 0 : _a.publicKey);
        });
    }
    /**
     * get the public key hash
     */
    getPublicKeyHash() {
        return __awaiter(this, void 0, void 0, function* () {
            return getHexHash(this.keyPair.publicKey);
        });
    }
    /**
     * Create a cryptobox server
     *
     * @param otherPublicKey
     * @param selfKeypair
     */
    createCryptoBoxServer(otherPublicKey, selfKeypair) {
        return __awaiter(this, void 0, void 0, function* () {
            return serverSessionKeys({
                publicKey: convertPublicKeyToX25519(selfKeypair.publicKey),
                secretKey: convertSecretKeyToX25519(selfKeypair.secretKey)
            }, convertPublicKeyToX25519(Buffer.from(otherPublicKey, 'hex')));
        });
    }
    /**
     * Create a cryptobox client
     *
     * @param otherPublicKey
     * @param selfKeypair
     */
    createCryptoBoxClient(otherPublicKey, selfKeypair) {
        return __awaiter(this, void 0, void 0, function* () {
            return clientSessionKeys({
                publicKey: convertPublicKeyToX25519(selfKeypair.publicKey),
                secretKey: convertSecretKeyToX25519(selfKeypair.secretKey)
            }, convertPublicKeyToX25519(Buffer.from(otherPublicKey, 'hex')));
        });
    }
    /**
     * Encrypt a message for a specific publicKey (receiver, asymmetric)
     *
     * @param recipientPublicKey
     * @param message
     */
    encryptMessageAsymmetric(recipientPublicKey, message) {
        return __awaiter(this, void 0, void 0, function* () {
            return sealCryptobox(message, Buffer.from(recipientPublicKey, 'hex'));
        });
    }
}
