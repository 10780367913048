/**
 * @internalapi
 */
export var Origin;
(function (Origin) {
    Origin["WEBSITE"] = "website";
    Origin["EXTENSION"] = "extension";
    Origin["P2P"] = "p2p";
    Origin["WALLETCONNECT"] = "walletconnect";
})(Origin || (Origin = {}));
