var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BEACON_VERSION, CommunicationClient, Serializer } from '@airgap/beacon-core';
import { SignClient } from '@walletconnect/sign-client';
import { getSdkError } from '@walletconnect/utils';
import { ActiveAccountUnspecified, ActiveNetworkUnspecified, InvalidNetworkOrAccount, InvalidReceivedSessionNamespace, InvalidSession, MissingRequiredScope, NotConnected } from '../error';
import { BeaconErrorType, BeaconMessageType, PermissionScope } from '@airgap/beacon-types';
import { generateGUID, getAddressFromPublicKey } from '@airgap/beacon-utils';
const TEZOS_PLACEHOLDER = 'tezos';
export var PermissionScopeMethods;
(function (PermissionScopeMethods) {
    PermissionScopeMethods["GET_ACCOUNTS"] = "tezos_getAccounts";
    PermissionScopeMethods["OPERATION_REQUEST"] = "tezos_send";
    PermissionScopeMethods["SIGN"] = "tezos_sign";
})(PermissionScopeMethods || (PermissionScopeMethods = {}));
export var PermissionScopeEvents;
(function (PermissionScopeEvents) {
    PermissionScopeEvents["CHAIN_CHANGED"] = "chainChanged";
    PermissionScopeEvents["ACCOUNTS_CHANGED"] = "accountsChanged";
    PermissionScopeEvents["REQUEST_ACKNOWLEDGED"] = "requestAcknowledged";
})(PermissionScopeEvents || (PermissionScopeEvents = {}));
export class WalletConnectCommunicationClient extends CommunicationClient {
    constructor(wcOptions) {
        super();
        this.wcOptions = wcOptions;
        this.activeListeners = new Map();
        this.channelOpeningListeners = new Map();
        this.getSignClient();
    }
    static getInstance(wcOptions) {
        if (!WalletConnectCommunicationClient.instance) {
            WalletConnectCommunicationClient.instance = new WalletConnectCommunicationClient(wcOptions);
        }
        return WalletConnectCommunicationClient.instance;
    }
    listenForEncryptedMessage(senderPublicKey, messageCallback) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.activeListeners.has(senderPublicKey)) {
                return;
            }
            const callbackFunction = (message) => __awaiter(this, void 0, void 0, function* () {
                messageCallback(message);
            });
            this.activeListeners.set(senderPublicKey, callbackFunction);
        });
    }
    listenForChannelOpening(messageCallback) {
        return __awaiter(this, void 0, void 0, function* () {
            const callbackFunction = (pairingResponse) => __awaiter(this, void 0, void 0, function* () {
                messageCallback(pairingResponse);
            });
            this.channelOpeningListeners.set('channelOpening', callbackFunction);
        });
    }
    unsubscribeFromEncryptedMessages() {
        return __awaiter(this, void 0, void 0, function* () {
            // implementation
        });
    }
    unsubscribeFromEncryptedMessage(_senderPublicKey) {
        return __awaiter(this, void 0, void 0, function* () {
            // implementation
        });
    }
    sendMessage(_message, _peer) {
        return __awaiter(this, void 0, void 0, function* () {
            const serializer = new Serializer();
            const message = (yield serializer.deserialize(_message));
            if (!message) {
                return;
            }
            this.currentMessageId = message.id;
            switch (message.type) {
                case BeaconMessageType.PermissionRequest:
                    this.requestPermissions(message);
                    break;
                case BeaconMessageType.OperationRequest:
                    this.sendOperations(message);
                    break;
                case BeaconMessageType.SignPayloadRequest:
                    this.signPayload(message);
                    break;
                default:
                    return;
            }
        });
    }
    fetchAccounts(topic, chainId) {
        return __awaiter(this, void 0, void 0, function* () {
            const signClient = yield this.getSignClient();
            return signClient.request({
                topic: topic,
                chainId: chainId,
                request: {
                    method: PermissionScopeMethods.GET_ACCOUNTS,
                    params: {}
                }
            });
        });
    }
    requestPermissions(message) {
        var _a, _b, _c, _d, _e;
        return __awaiter(this, void 0, void 0, function* () {
            console.log('#### Requesting permissions');
            if (!this.getPermittedMethods().includes(PermissionScopeMethods.GET_ACCOUNTS)) {
                throw new MissingRequiredScope(PermissionScopeMethods.GET_ACCOUNTS);
            }
            if (this.activeAccount) {
                try {
                    yield this.openSession();
                }
                catch (error) {
                    console.error(error.message);
                    return;
                }
            }
            this.setDefaultAccountAndNetwork();
            const session = this.getSession();
            let publicKey;
            if (((_a = session.sessionProperties) === null || _a === void 0 ? void 0 : _a.pubkey) &&
                ((_b = session.sessionProperties) === null || _b === void 0 ? void 0 : _b.algo) &&
                ((_c = session.sessionProperties) === null || _c === void 0 ? void 0 : _c.address)) {
                publicKey = (_d = session.sessionProperties) === null || _d === void 0 ? void 0 : _d.pubkey;
                console.log('[requestPermissions]: Have pubkey in sessionProperties, skipping "get_accounts" call', session.sessionProperties);
            }
            else {
                const accounts = this.getTezosNamespace(session.namespaces).accounts;
                const addressOrPbk = accounts[0].split(':', 3)[2];
                if (addressOrPbk.startsWith('edpk')) {
                    publicKey = addressOrPbk;
                }
                else {
                    if (message.network.type !== this.wcOptions.network) {
                        throw new Error('Network in permission request is not the same as preferred network!');
                    }
                    const result = yield this.fetchAccounts(session.topic, `${TEZOS_PLACEHOLDER}:${message.network.type}`);
                    if (!result || result.length < 1) {
                        throw new Error('No account shared by wallet');
                    }
                    if (result.some((account) => !account.pubkey)) {
                        throw new Error('Public Key in `tezos_getAccounts` is empty!');
                    }
                    publicKey = (_e = result[0]) === null || _e === void 0 ? void 0 : _e.pubkey;
                }
            }
            if (!publicKey) {
                throw new Error('Public Key in `tezos_getAccounts` is empty!');
            }
            const permissionResponse = {
                type: BeaconMessageType.PermissionResponse,
                appMetadata: {
                    senderId: session.pairingTopic,
                    name: session.peer.metadata.name,
                    icon: session.peer.metadata.icons[0]
                },
                publicKey,
                network: message.network,
                scopes: [PermissionScope.SIGN, PermissionScope.OPERATION_REQUEST],
                id: this.currentMessageId
            };
            this.notifyListeners(session.pairingTopic, permissionResponse);
        });
    }
    /**
     * @description Once the session is establish, send payload to be approved and signed by the wallet.
     * @error MissingRequiredScope is thrown if permission to sign payload was not granted
     */
    signPayload(signPayloadRequest) {
        return __awaiter(this, void 0, void 0, function* () {
            const signClient = yield this.getSignClient();
            const session = this.getSession();
            if (!this.getPermittedMethods().includes(PermissionScopeMethods.SIGN)) {
                throw new MissingRequiredScope(PermissionScopeMethods.SIGN);
            }
            const network = this.getActiveNetwork();
            const account = yield this.getPKH();
            this.validateNetworkAndAccount(network, account);
            // TODO: Type
            signClient
                .request({
                topic: session.topic,
                chainId: `${TEZOS_PLACEHOLDER}:${network}`,
                request: {
                    method: PermissionScopeMethods.SIGN,
                    params: {
                        account: account,
                        payload: signPayloadRequest.payload
                    }
                }
            })
                .then((response) => {
                const signPayloadResponse = {
                    type: BeaconMessageType.SignPayloadResponse,
                    signingType: signPayloadRequest.signingType,
                    signature: response === null || response === void 0 ? void 0 : response.signature,
                    id: this.currentMessageId
                };
                this.notifyListeners(session.pairingTopic, signPayloadResponse);
            })
                .catch(() => __awaiter(this, void 0, void 0, function* () {
                const errorResponse = {
                    type: BeaconMessageType.Error,
                    id: this.currentMessageId,
                    errorType: BeaconErrorType.ABORTED_ERROR
                };
                this.notifyListeners(session.pairingTopic, errorResponse);
            }));
        });
    }
    /**
     * @description Once the session is established, send Tezos operations to be approved, signed and inject by the wallet.
     * @error MissingRequiredScope is thrown if permission to send operation was not granted
     */
    sendOperations(operationRequest) {
        return __awaiter(this, void 0, void 0, function* () {
            const signClient = yield this.getSignClient();
            const session = this.getSession();
            if (!this.getPermittedMethods().includes(PermissionScopeMethods.OPERATION_REQUEST)) {
                throw new MissingRequiredScope(PermissionScopeMethods.OPERATION_REQUEST);
            }
            const network = this.getActiveNetwork();
            const account = yield this.getPKH();
            this.validateNetworkAndAccount(network, account);
            signClient
                .request({
                topic: session.topic,
                chainId: `${TEZOS_PLACEHOLDER}:${network}`,
                request: {
                    method: PermissionScopeMethods.OPERATION_REQUEST,
                    params: {
                        account,
                        operations: operationRequest.operationDetails
                    }
                }
            })
                .then((response) => {
                var _a, _b, _c;
                const sendOperationResponse = {
                    type: BeaconMessageType.OperationResponse,
                    transactionHash: (_c = (_b = (_a = response.operationHash) !== null && _a !== void 0 ? _a : response.transactionHash) !== null && _b !== void 0 ? _b : response.hash) !== null && _c !== void 0 ? _c : '',
                    id: this.currentMessageId
                };
                this.notifyListeners(session.pairingTopic, sendOperationResponse);
            })
                .catch(() => __awaiter(this, void 0, void 0, function* () {
                const errorResponse = {
                    type: BeaconMessageType.Error,
                    id: this.currentMessageId,
                    errorType: BeaconErrorType.ABORTED_ERROR
                };
                this.notifyListeners(session.pairingTopic, errorResponse);
            }));
        });
    }
    init(forceNewConnection = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const signClient = yield this.getSignClient();
            if (forceNewConnection) {
                this.closePairings();
            }
            const sessions = signClient.session.getAll();
            if (sessions && sessions.length > 0) {
                this.session = sessions[0];
                this.setDefaultAccountAndNetwork();
                return undefined;
            }
            const { uri, topic } = yield signClient.core.pairing.create();
            signClient.core.pairing.ping({ topic }).then(() => __awaiter(this, void 0, void 0, function* () {
                yield signClient.core.pairing.activate({ topic });
                // pairings don't have peer details
                // therefore we must immediately open a session
                // to get data required in the pairing response
                try {
                    const session = yield this.openSession(topic);
                    const pairingResponse = {
                        id: topic,
                        type: 'walletconnect-pairing-response',
                        name: session.peer.metadata.name,
                        publicKey: session.peer.publicKey,
                        senderId: topic,
                        extensionId: session.peer.metadata.name,
                        version: '3'
                    };
                    this.channelOpeningListeners.forEach((listener) => {
                        listener(pairingResponse);
                    });
                }
                catch (error) {
                    console.error(error.message);
                    const fun = this.eventHandlers.get("CLOSE_ALERT" /* ClientEvents.CLOSE_ALERT */);
                    fun && fun();
                    return;
                }
            }));
            return { uri, topic };
        });
    }
    close() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.closePairings();
        });
    }
    subscribeToSessionEvents(signClient) {
        signClient.on('session_event', (event) => {
            if (event.params.event.name === PermissionScopeEvents.REQUEST_ACKNOWLEDGED &&
                this.currentMessageId) {
                this.acknowledgeRequest(this.currentMessageId);
            }
        });
        signClient.on('session_update', (event) => {
            this.updateActiveAccount(event.params.namespaces);
        });
        signClient.on('session_delete', (event) => {
            this.disconnect(signClient, { type: 'session', topic: event.topic });
        });
        signClient.on('session_expire', (event) => {
            this.disconnect(signClient, { type: 'session', topic: event.topic });
        });
        signClient.core.pairing.events.on('pairing_delete', (event) => {
            this.disconnect(signClient, { type: 'pairing', topic: event.topic });
        });
    }
    acknowledgeRequest(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const session = this.getSession();
            const acknowledgeResponse = {
                type: BeaconMessageType.Acknowledge,
                id
            };
            this.notifyListeners(session.pairingTopic, acknowledgeResponse);
        });
    }
    updateActiveAccount(namespaces) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const accounts = this.getTezosNamespace(namespaces).accounts;
                if (accounts.length === 1) {
                    const [_namespace, chainId, addressOrPbk] = accounts[0].split(':', 3);
                    const session = this.getSession();
                    let publicKey;
                    this.activeNetwork = chainId;
                    if (addressOrPbk.startsWith('edpk')) {
                        publicKey = addressOrPbk;
                        this.activeAccount = yield getAddressFromPublicKey(publicKey);
                    }
                    else {
                        this.activeAccount = addressOrPbk;
                        const result = yield this.fetchAccounts(session.topic, `${TEZOS_PLACEHOLDER}:${chainId}`);
                        publicKey = (_a = result === null || result === void 0 ? void 0 : result.find(({ address: _address }) => addressOrPbk === _address)) === null || _a === void 0 ? void 0 : _a.pubkey;
                    }
                    if (!publicKey) {
                        throw new Error('Public key for the new account not provided');
                    }
                    this.notifyListeners(session.pairingTopic, {
                        id: yield generateGUID(),
                        type: BeaconMessageType.ChangeAccountRequest,
                        publicKey,
                        network: { type: chainId },
                        scopes: [PermissionScope.SIGN, PermissionScope.OPERATION_REQUEST]
                    });
                }
            }
            catch (_b) { }
        });
    }
    disconnect(signClient, trigger) {
        return __awaiter(this, void 0, void 0, function* () {
            let session;
            if (trigger.type === 'session') {
                session = yield this.onSessionClosed(signClient, trigger.topic);
            }
            if (trigger.type === 'pairing') {
                session = yield this.onPairingClosed(signClient, trigger.topic);
            }
            if (!this.activeAccount) {
                const fun = this.eventHandlers.get("RESET_STATE" /* ClientEvents.RESET_STATE */);
                fun && fun();
            }
            if (!session) {
                return;
            }
            this.notifyListeners(session.pairingTopic, {
                id: yield generateGUID(),
                type: BeaconMessageType.Disconnect
            });
            this.clearState();
        });
    }
    onPairingClosed(signClient, pairingTopic) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const session = ((_a = this.session) === null || _a === void 0 ? void 0 : _a.pairingTopic) === pairingTopic
                ? this.session
                : signClient.session
                    .getAll()
                    .find((session) => session.pairingTopic === pairingTopic);
            if (!session) {
                return undefined;
            }
            try {
                yield signClient.disconnect({
                    topic: session.topic,
                    reason: {
                        code: -1,
                        message: 'Pairing deleted'
                    }
                });
            }
            catch (error) {
                // If the session was already closed, `disconnect` will throw an error.
                console.warn(error);
            }
            return session;
        });
    }
    onSessionClosed(signClient, sessionTopic) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.session || this.session.topic !== sessionTopic) {
                return undefined;
            }
            try {
                yield signClient.core.pairing.disconnect({ topic: this.session.pairingTopic });
            }
            catch (error) {
                // If the pairing was already closed, `disconnect` will throw an error.
                console.warn(error);
            }
            return this.session;
        });
    }
    getPairingRequestInfo() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const { uri, topic } = (_a = (yield this.init(true))) !== null && _a !== void 0 ? _a : {};
            return {
                id: topic,
                type: 'walletconnect-pairing-request',
                name: 'WalletConnect',
                version: BEACON_VERSION,
                uri: uri,
                senderId: yield generateGUID(),
                publicKey: yield generateGUID()
            };
        });
    }
    closePairings() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            yield this.closeSessions();
            const signClient = yield this.getSignClient();
            const pairings = (_a = signClient.pairing.getAll()) !== null && _a !== void 0 ? _a : [];
            for (let pairing of pairings) {
                yield signClient.core.pairing.disconnect({ topic: pairing.topic });
            }
        });
    }
    closeSessions() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const signClient = yield this.getSignClient();
            const sessions = (_a = signClient.session.getAll()) !== null && _a !== void 0 ? _a : [];
            for (let session of sessions) {
                yield signClient.disconnect({
                    topic: session.topic,
                    reason: {
                        code: 0,
                        message: 'Force new connection'
                    }
                });
            }
            this.clearState();
        });
    }
    openSession(pairingTopic) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            const signClient = yield this.getSignClient();
            const permissionScopeParams = {
                networks: [this.wcOptions.network],
                events: [],
                methods: [
                    PermissionScopeMethods.GET_ACCOUNTS,
                    PermissionScopeMethods.OPERATION_REQUEST,
                    PermissionScopeMethods.SIGN
                ]
            };
            const optionalPermissionScopeParams = {
                networks: [this.wcOptions.network],
                events: [PermissionScopeEvents.REQUEST_ACKNOWLEDGED],
                methods: []
            };
            const connectParams = {
                requiredNamespaces: {
                    [TEZOS_PLACEHOLDER]: this.permissionScopeParamsToNamespaces(permissionScopeParams)
                },
                optionalNamespaces: {
                    [TEZOS_PLACEHOLDER]: this.permissionScopeParamsToNamespaces(optionalPermissionScopeParams)
                },
                pairingTopic: pairingTopic !== null && pairingTopic !== void 0 ? pairingTopic : (_a = signClient.core.pairing.getPairings()[0]) === null || _a === void 0 ? void 0 : _a.topic
            };
            const { approval } = yield signClient.connect(connectParams);
            try {
                const session = yield approval();
                // if I have successfully opened a session and I already have one opened
                if (session && this.session) {
                    yield this.closeSessions(); // close the previous session
                }
                // I still need this check in the event the user aborts the sync process on the wallet side
                // but there is already a connection set
                this.session = (_b = this.session) !== null && _b !== void 0 ? _b : session;
                this.validateReceivedNamespace(permissionScopeParams, this.session.namespaces);
            }
            catch (error) {
                console.error(error.message);
                const _pairingTopic = pairingTopic !== null && pairingTopic !== void 0 ? pairingTopic : (_c = signClient.core.pairing.getPairings()[0]) === null || _c === void 0 ? void 0 : _c.topic;
                const errorResponse = {
                    type: BeaconMessageType.Error,
                    id: this.currentMessageId,
                    errorType: BeaconErrorType.ABORTED_ERROR
                };
                this.notifyListeners(_pairingTopic, errorResponse);
            }
            if (this.session) {
                return this.session;
            }
            else {
                throw new InvalidSession('No session set.');
            }
        });
    }
    permissionScopeParamsToNamespaces(permissionScopeParams) {
        var _a;
        return {
            chains: permissionScopeParams.networks.map((network) => `${TEZOS_PLACEHOLDER}:${network}`),
            methods: permissionScopeParams.methods,
            events: (_a = permissionScopeParams.events) !== null && _a !== void 0 ? _a : []
        };
    }
    validateReceivedNamespace(scope, receivedNamespaces) {
        if (receivedNamespaces[TEZOS_PLACEHOLDER]) {
            this.validateMethods(scope.methods, receivedNamespaces[TEZOS_PLACEHOLDER].methods);
            if (scope.events) {
                this.validateEvents(scope.events, receivedNamespaces['tezos'].events);
            }
            this.validateAccounts(scope.networks, receivedNamespaces[TEZOS_PLACEHOLDER].accounts);
        }
        else {
            this.clearState();
            throw new InvalidReceivedSessionNamespace('All namespaces must be approved', getSdkError('USER_REJECTED').code, 'incomplete', 'tezos');
        }
    }
    validateMethods(requiredMethods, receivedMethods) {
        const missingMethods = [];
        requiredMethods.forEach((method) => {
            if (!receivedMethods.includes(method)) {
                missingMethods.push(method);
            }
        });
        if (missingMethods.length > 0) {
            this.clearState();
            throw new InvalidReceivedSessionNamespace('All methods must be approved', getSdkError('USER_REJECTED_METHODS').code, 'incomplete', missingMethods);
        }
    }
    validateEvents(requiredEvents, receivedEvents) {
        const missingEvents = [];
        requiredEvents.forEach((method) => {
            if (!receivedEvents.includes(method)) {
                missingEvents.push(method);
            }
        });
        if (missingEvents.length > 0) {
            this.clearState();
            throw new InvalidReceivedSessionNamespace('All events must be approved', getSdkError('USER_REJECTED_EVENTS').code, 'incomplete', missingEvents);
        }
    }
    validateAccounts(requiredNetwork, receivedAccounts) {
        if (receivedAccounts.length === 0) {
            this.clearState();
            throw new InvalidReceivedSessionNamespace('Accounts must not be empty', getSdkError('USER_REJECTED_CHAINS').code, 'incomplete');
        }
        const receivedChains = [];
        const invalidChains = [];
        const missingChains = [];
        const invalidChainsNamespace = [];
        receivedAccounts.forEach((chain) => {
            const accountId = chain.split(':');
            if (accountId.length !== 3) {
                invalidChains.push(chain);
            }
            if (accountId[0] !== TEZOS_PLACEHOLDER) {
                invalidChainsNamespace.push(chain);
            }
            const network = accountId[1];
            if (!receivedChains.includes(network)) {
                receivedChains.push(network);
            }
        });
        if (invalidChains.length > 0) {
            this.clearState();
            throw new InvalidReceivedSessionNamespace('Accounts must be CAIP-10 compliant', getSdkError('USER_REJECTED_CHAINS').code, 'invalid', invalidChains);
        }
        if (invalidChainsNamespace.length > 0) {
            this.clearState();
            throw new InvalidReceivedSessionNamespace('Accounts must be defined in matching namespace', getSdkError('UNSUPPORTED_ACCOUNTS').code, 'invalid', invalidChainsNamespace);
        }
        requiredNetwork.forEach((network) => {
            if (!receivedChains.includes(network)) {
                missingChains.push(network);
            }
        });
        if (missingChains.length > 0) {
            this.clearState();
            throw new InvalidReceivedSessionNamespace('All chains must have at least one account', getSdkError('USER_REJECTED_CHAINS').code, 'incomplete', missingChains);
        }
    }
    validateNetworkAndAccount(network, account) {
        if (!this.getTezosNamespace().accounts.includes(`${TEZOS_PLACEHOLDER}:${network}:${account}`)) {
            throw new InvalidNetworkOrAccount(network, account);
        }
    }
    /**
     * @description Access the active network
     * @error ActiveNetworkUnspecified thorwn when there are multiple Tezos netwroks in the session and none is set as the active one
     */
    getActiveNetwork() {
        if (!this.activeNetwork) {
            this.getSession();
            throw new ActiveNetworkUnspecified();
        }
        return this.activeNetwork;
    }
    setDefaultAccountAndNetwork() {
        const activeAccount = this.getAccounts();
        if (activeAccount.length === 1) {
            this.activeAccount = activeAccount[0];
        }
        const activeNetwork = this.getNetworks();
        if (activeNetwork.length === 1) {
            this.activeNetwork = activeNetwork[0];
        }
    }
    /**
     * @description Return all connected accounts from the active session
     * @error NotConnected if no active session
     */
    getAccounts() {
        return this.getTezosNamespace().accounts.map((account) => account.split(':')[2]);
    }
    /**
     * @description Return all networks from the namespace of the active session
     * @error NotConnected if no active session
     */
    getNetworks() {
        return this.getPermittedNetwork();
    }
    getTezosNamespace(namespaces = this.getSession().namespaces) {
        if (TEZOS_PLACEHOLDER in namespaces) {
            return namespaces[TEZOS_PLACEHOLDER];
        }
        else {
            throw new InvalidSession('Tezos not found in namespaces');
        }
    }
    getPermittedMethods() {
        return this.getTezosRequiredNamespace().methods;
    }
    getPermittedNetwork() {
        return this.getTezosRequiredNamespace().chains.map((chain) => chain.split(':')[1]);
    }
    getTezosRequiredNamespace() {
        return {
            chains: [`${TEZOS_PLACEHOLDER}:${this.wcOptions.network}`],
            events: [],
            methods: ['tezos_getAccounts', 'tezos_send', 'tezos_sign']
        };
        // if (TEZOS_PLACEHOLDER in this.getSession().requiredNamespaces) {
        //   return this.getSession().requiredNamespaces[TEZOS_PLACEHOLDER] as {
        //     chains: string[]
        //     methods: string[]
        //     events: string[]
        //   }
        // } else {
        //   throw new InvalidSession('Tezos not found in requiredNamespaces')
        // }
    }
    notifyListeners(pairingTopic, partialResponse) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = Object.assign(Object.assign({}, partialResponse), { version: '2', senderId: pairingTopic });
            const serializer = new Serializer();
            const serialized = yield serializer.serialize(response);
            this.activeListeners.forEach((listener) => {
                listener(serialized);
            });
        });
    }
    currentSession() {
        return this.session;
    }
    getSignClient() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.signClient === undefined) {
                this.signClient = yield SignClient.init(this.wcOptions.opts);
                this.subscribeToSessionEvents(this.signClient);
            }
            return this.signClient;
        });
    }
    getSession() {
        if (!this.session) {
            throw new NotConnected();
        }
        return this.session;
    }
    /**
     * @description Access the public key hash of the active account
     * @error ActiveAccountUnspecified thrown when there are multiple Tezos account in the session and none is set as the active one
     */
    getPKH() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.activeAccount) {
                this.getSession();
                throw new ActiveAccountUnspecified();
            }
            return this.activeAccount;
        });
    }
    clearState() {
        this.session = undefined;
        this.activeAccount = undefined;
        this.activeNetwork = undefined;
    }
}
