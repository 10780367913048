/**
 * @internalapi
 */
export var StorageKey;
(function (StorageKey) {
    StorageKey["TRANSPORT_P2P_PEERS_DAPP"] = "beacon:communication-peers-dapp";
    StorageKey["TRANSPORT_P2P_PEERS_WALLET"] = "beacon:communication-peers-wallet";
    StorageKey["TRANSPORT_POSTMESSAGE_PEERS_DAPP"] = "beacon:postmessage-peers-dapp";
    StorageKey["TRANSPORT_POSTMESSAGE_PEERS_WALLET"] = "beacon:postmessage-peers-wallet";
    StorageKey["TRANSPORT_WALLETCONNECT_PEERS_DAPP"] = "beacon:walletconnect-peers-dapp";
    StorageKey["LAST_SELECTED_WALLET"] = "beacon:last-selected-wallet";
    StorageKey["ACCOUNTS"] = "beacon:accounts";
    StorageKey["ACTIVE_ACCOUNT"] = "beacon:active-account";
    StorageKey["PUSH_TOKENS"] = "beacon:push-tokens";
    StorageKey["BEACON_SDK_SECRET_SEED"] = "beacon:sdk-secret-seed";
    StorageKey["APP_METADATA_LIST"] = "beacon:app-metadata-list";
    StorageKey["PERMISSION_LIST"] = "beacon:permissions";
    StorageKey["BEACON_SDK_VERSION"] = "beacon:sdk_version";
    StorageKey["MATRIX_PRESERVED_STATE"] = "beacon:sdk-matrix-preserved-state";
    StorageKey["MATRIX_PEER_ROOM_IDS"] = "beacon:matrix-peer-rooms";
    StorageKey["MATRIX_SELECTED_NODE"] = "beacon:matrix-selected-node";
    StorageKey["MULTI_NODE_SETUP_DONE"] = "beacon:multi-node-setup";
})(StorageKey || (StorageKey = {}));
