export var NetworkType;
(function (NetworkType) {
    NetworkType["MAINNET"] = "mainnet";
    NetworkType["GHOSTNET"] = "ghostnet";
    NetworkType["MONDAYNET"] = "mondaynet";
    NetworkType["DAILYNET"] = "dailynet";
    NetworkType["DELPHINET"] = "delphinet";
    NetworkType["EDONET"] = "edonet";
    NetworkType["FLORENCENET"] = "florencenet";
    NetworkType["GRANADANET"] = "granadanet";
    NetworkType["HANGZHOUNET"] = "hangzhounet";
    NetworkType["ITHACANET"] = "ithacanet";
    NetworkType["JAKARTANET"] = "jakartanet";
    NetworkType["KATHMANDUNET"] = "kathmandunet";
    NetworkType["LIMANET"] = "limanet";
    NetworkType["MUMBAINET"] = "mumbainet";
    NetworkType["NAIROBINET"] = "nairobinet";
    NetworkType["OXFORDNET"] = "oxfordnet";
    NetworkType["CUSTOM"] = "custom";
})(NetworkType || (NetworkType = {}));
