import { FC } from "react";
import cx from "classnames";

import { Button } from "components/ui/Button";
import { AUDIT_LINK } from "constants/defaults";
import { ReactComponent as AuditLogo } from "svg/AuditLogo.svg";

import s from "./AuditButton.module.sass";

type AutditButtonProps = {
  className?: string;
};

export const AuditButton: FC<AutditButtonProps> = ({ className }) => {
  return (
    <Button
      theme="clear"
      external
      href={AUDIT_LINK}
      className={cx(s.root, className)}
    >
      <AuditLogo /> <span className={s.text}>Audited</span>
    </Button>
  );
};
