var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { WalletConnectCommunicationClient } from './communication-client/WalletConnectCommunicationClient';
import { Origin, TransportStatus } from '@airgap/beacon-types';
import { Transport, PeerManager } from '@airgap/beacon-core';
/**
 * @internalapi
 *
 *
 */
export class WalletConnectTransport extends Transport {
    // public readonly type: TransportType = TransportType.WALLETCONNECT
    constructor(name, _keyPair, storage, storageKey, wcOptions) {
        super(name, WalletConnectCommunicationClient.getInstance(wcOptions), new PeerManager(storage, storageKey));
        this.wcOptions = wcOptions;
    }
    static isAvailable() {
        return __awaiter(this, void 0, void 0, function* () {
            return Promise.resolve(true);
        });
    }
    connect() {
        const _super = Object.create(null, {
            connect: { get: () => super.connect }
        });
        return __awaiter(this, void 0, void 0, function* () {
            if (this._isConnected !== TransportStatus.NOT_CONNECTED) {
                return;
            }
            this._isConnected = TransportStatus.CONNECTING;
            yield this.client.init();
            const knownPeers = yield this.getPeers();
            if (knownPeers.length > 0) {
                knownPeers.map((peer) => __awaiter(this, void 0, void 0, function* () { return this.listen(peer.publicKey); }));
            }
            yield this.startOpenChannelListener();
            return _super.connect.call(this);
        });
    }
    getPeers() {
        return __awaiter(this, void 0, void 0, function* () {
            const client = WalletConnectCommunicationClient.getInstance(this.wcOptions);
            const session = client.currentSession();
            if (!session) {
                return [];
            }
            return [
                {
                    senderId: session.peer.publicKey,
                    extensionId: session.peer.metadata.name,
                    id: session.peer.publicKey,
                    type: 'walletconnect-pairing-response',
                    name: 'peer',
                    publicKey: session.peer.publicKey,
                    version: 'first'
                }
            ];
        });
    }
    disconnect() {
        const _super = Object.create(null, {
            disconnect: { get: () => super.disconnect }
        });
        return __awaiter(this, void 0, void 0, function* () {
            yield this.client.close();
            return _super.disconnect.call(this);
        });
    }
    startOpenChannelListener() {
        return __awaiter(this, void 0, void 0, function* () {
            //
        });
    }
    getPairingRequestInfo() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.getPairingRequestInfo();
        });
    }
    listen(publicKey) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.client
                .listenForEncryptedMessage(publicKey, (message) => {
                const connectionContext = {
                    origin: Origin.WALLETCONNECT,
                    id: publicKey
                };
                this.notifyListeners(message, connectionContext).catch((error) => {
                    throw error;
                });
            })
                .catch((error) => {
                throw error;
            });
        });
    }
}
