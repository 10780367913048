import { FieldValues, FieldErrors } from "react-hook-form";

export function getAdvancedErrorMessage<T extends FieldValues>(
  formError: FieldErrors<T> | undefined,
): string | undefined {
  if (formError) {
    // @ts-ignore
    return formError.message;
  }
  return undefined;
}
