import { getDebugEnabled } from '../debug';
/**
 * The logger that is used internally
 */
export class InternalLogger {
    constructor() { }
    debug(name, method, ...args) {
        this._log('debug', name, method, args);
    }
    log(name, method, ...args) {
        this._log('log', name, method, args);
    }
    warn(name, method, ...args) {
        this._log('warn', name, method, args);
    }
    error(name, method, ...args) {
        this._log('error', name, method, args);
    }
    time(start, label) {
        start ? console.time(label) : console.timeEnd(label);
    }
    timeLog(name, method, ...args) {
        this._log('timeLog', name, method, args);
    }
    _log(type, name, method, args = []) {
        if (!getDebugEnabled()) {
            return;
        }
        let groupText = `[BEACON] ${new Date().toISOString()} [${name}](${method})`;
        let data = args;
        if (args[0] && typeof args[0] === 'string') {
            groupText += ` ${args[0]}`;
            data = args.slice(1);
        }
        switch (type) {
            case 'error':
                console.group(groupText);
                console.error(...data);
                break;
            case 'warn':
                console.group(groupText);
                console.warn(...data);
                break;
            case 'debug':
                console.groupCollapsed(groupText);
                console.debug(...data);
                break;
            case 'timeLog':
                console.group(groupText);
                console.timeLog(...data);
                break;
            default:
                console.group(groupText);
                console.log(...data);
        }
        console.groupEnd();
        // echo.group(echo.asWarning(`[BEACON] ${message}`))
        // echo.log(echo.asWarning(`[${this.name}]`), echo.asAlert(`(${method})`), ...args)
        // echo.groupEnd()
    }
}
export class Logger {
    constructor(service) {
        this.name = service;
    }
    debug(method, ...args) {
        logger.debug(this.name, method, args);
    }
    log(method, ...args) {
        logger.log(this.name, method, args);
    }
    warn(method, ...args) {
        logger.warn(this.name, method, args);
    }
    error(method, ...args) {
        logger.error(this.name, method, args);
    }
    time(start, label) {
        logger.time(start, label);
    }
    timeLog(method, ...args) {
        logger.timeLog(method, args);
    }
}
const loggerWrapper = new Logger('');
let logger = new InternalLogger();
export const setLogger = (newLogger) => {
    logger = newLogger;
};
export const getLogger = () => loggerWrapper;
