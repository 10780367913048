var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { TransportType, ExtensionMessageTarget, TransportStatus, Origin } from '@airgap/beacon-types';
import { Transport, PeerManager, Logger, windowRef } from '@airgap/beacon-core';
import { PostMessageClient } from './PostMessageClient';
const logger = new Logger('PostMessageTransport');
let listeningForExtensions = false;
let extensionsPromise;
let extensions;
const addExtension = (extension) => {
    if (!extensions) {
        extensions = [];
    }
    if (!extensions.some((ext) => ext.id === extension.id)) {
        extensions.push(extension);
        windowRef.postMessage('extensionsUpdated', windowRef.location.origin);
    }
};
/**
 * @internalapi
 *
 *
 */
export class PostMessageTransport extends Transport {
    constructor(name, keyPair, storage, storageKey) {
        super(name, new PostMessageClient(name, keyPair), new PeerManager(storage, storageKey));
        this.type = TransportType.POST_MESSAGE;
    }
    static isAvailable() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const fn = (event) => {
                    const data = event.data;
                    if (data && data.payload === 'pong') {
                        resolve(true);
                        windowRef.removeEventListener('message', fn);
                    }
                };
                windowRef.addEventListener('message', fn);
                const message = {
                    target: ExtensionMessageTarget.EXTENSION,
                    payload: 'ping'
                };
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                windowRef.postMessage(message, windowRef.location.origin);
            });
        });
    }
    static getAvailableExtensions() {
        return __awaiter(this, void 0, void 0, function* () {
            if (extensionsPromise) {
                return extensionsPromise;
            }
            if (extensions) {
                return extensions;
            }
            extensions = [];
            extensionsPromise = new Promise((resolve) => {
                PostMessageTransport.listenForExtensions();
                setTimeout(() => {
                    resolve(extensions !== null && extensions !== void 0 ? extensions : []);
                }, 1000);
            }).finally(() => {
                extensionsPromise = undefined;
            });
            return extensionsPromise;
        });
    }
    static listenForExtensions() {
        if (listeningForExtensions) {
            return;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fn = (event) => {
            if (event.source !== windowRef || event.origin !== windowRef.location.origin) {
                // TODO: Add to error handler: console.debug('[Beacon]: Event received from untrusted origin')
                return;
            }
            const data = event.data;
            const sender = data.sender;
            if (data && data.payload === 'pong' && sender) {
                logger.log('getAvailableExtensions', `extension "${sender.name}" is available`, sender);
                addExtension(sender);
            }
        };
        windowRef.addEventListener('message', fn);
        const message = {
            target: ExtensionMessageTarget.EXTENSION,
            payload: 'ping'
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        windowRef.postMessage(message, windowRef.location.origin);
        listeningForExtensions = true;
    }
    connect() {
        const _super = Object.create(null, {
            connect: { get: () => super.connect }
        });
        return __awaiter(this, void 0, void 0, function* () {
            logger.log('connect');
            if (this._isConnected !== TransportStatus.NOT_CONNECTED) {
                return;
            }
            this._isConnected = TransportStatus.CONNECTING;
            const knownPeers = yield this.getPeers();
            if (knownPeers.length > 0) {
                logger.log('connect', `connecting to ${knownPeers.length} peers`);
                const connectionPromises = knownPeers.map((peer) => __awaiter(this, void 0, void 0, function* () { return this.listen(peer.publicKey); }));
                Promise.all(connectionPromises).catch((error) => logger.error('connect', error));
            }
            yield this.startOpenChannelListener();
            yield _super.connect.call(this);
        });
    }
    startOpenChannelListener() {
        return __awaiter(this, void 0, void 0, function* () {
            //
        });
    }
    getPairingRequestInfo() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.getPairingRequestInfo();
        });
    }
    listen(publicKey) {
        return __awaiter(this, void 0, void 0, function* () {
            logger.log('listen', publicKey);
            yield this.client
                .listenForEncryptedMessage(publicKey, (message, context) => {
                const connectionContext = {
                    origin: Origin.EXTENSION,
                    id: context.id
                };
                this.notifyListeners(message, connectionContext).catch((error) => {
                    throw error;
                });
            })
                .catch((error) => {
                throw error;
            });
        });
    }
}
// Start loading wallets async so they will be ready when the modal is opened
PostMessageTransport.getAvailableExtensions();
