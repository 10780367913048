var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getAccountIdentifier, Logger } from '@airgap/beacon-core';
import { BeaconMessageType, BeaconErrorType } from '@airgap/beacon-types';
import { getAddressFromPublicKey } from '@airgap/beacon-utils';
const logger = new Logger('OutgoingResponseInterceptor');
/**
 * @internalapi
 *
 * The OutgoingResponseInterceptor is used in the WalletClient to intercept an outgoing response and enrich it with data.
 */
export class OutgoingResponseInterceptor {
    static intercept(config) {
        return __awaiter(this, void 0, void 0, function* () {
            if (config.request.version === '2') {
                OutgoingResponseInterceptor.handleV2Message(config);
            }
            else if (config.request.version === '3') {
                OutgoingResponseInterceptor.handleV3Message(config);
            }
        });
    }
    static handleV3Message(config) {
        return __awaiter(this, void 0, void 0, function* () {
            const { 
            // senderId,
            // request,
            message: msg, 
            // ownAppMetadata,
            // permissionManager,
            appMetadataManager, interceptorCallback } = config;
            const wrappedMessage = msg;
            const v3Message = wrappedMessage.message;
            console.log('LOGGING OUTGOING V3', v3Message, appMetadataManager);
            interceptorCallback(msg);
            // switch (v3Message.type) {
            //   case BeaconMessageType.PermissionResponse:
            //     {
            //       const response: PermissionResponse = {
            //         senderId,
            //         version: BEACON_VERSION,
            //         appMetadata: ownAppMetadata,
            //         ...msg
            //       }
            //       const publicKey = response.publicKey
            //       const address: string = await getAddressFromPublicKey(publicKey)
            //       const appMetadata = await appMetadataManager.getAppMetadata(request.senderId)
            //       if (!appMetadata) {
            //         throw new Error('AppMetadata not found')
            //       }
            //       const permission: PermissionInfo = {
            //         accountIdentifier: await getAccountIdentifier(address, response.network),
            //         senderId: request.senderId,
            //         appMetadata,
            //         website: '',
            //         address,
            //         publicKey,
            //         network: response.network,
            //         scopes: response.scopes,
            //         connectedAt: new Date().getTime()
            //       }
            //       permissionManager.addPermission(permission).catch(console.error)
            //       interceptorCallback(response)
            //     }
            //     break
            //   case BeaconMessageType.BlockchainResponse:
            //     {
            //       // const appMetadata: AppMetadata = await IncomingRequestInterceptor.getAppMetadata(
            //       //   appMetadataManager,
            //       //   msg.senderId
            //       // )
            //       const request: any /* BeaconMessageWrapper<BlockchainRequestV3<string>> */ = {
            //         ...wrappedMessage
            //       }
            //       interceptorCallback(request)
            //     }
            //     break
            //   default:
            //     logger.log('intercept', 'Message not handled')
            //     assertNever(v3Message)
            // }
        });
    }
    static handleV2Message(config) {
        return __awaiter(this, void 0, void 0, function* () {
            const { senderId, request, message, ownAppMetadata, permissionManager, appMetadataManager, interceptorCallback } = config;
            switch (message.type) {
                case BeaconMessageType.Error: {
                    const response = {
                        type: message.type,
                        version: '2',
                        senderId,
                        id: message.id,
                        errorType: message.errorType
                    };
                    if (message.errorType === BeaconErrorType.TRANSACTION_INVALID_ERROR && message.errorData) {
                        const errorData = message.errorData;
                        // Check if error data is in correct format
                        if (Array.isArray(errorData) &&
                            errorData.every((item) => Boolean(item.kind) && Boolean(item.id))) {
                            response.errorData = message.errorData;
                        }
                        else {
                            logger.warn('ErrorData provided is not in correct format. It needs to be an array of RPC errors. It will not be included in the message sent to the dApp');
                        }
                    }
                    interceptorCallback(response);
                    break;
                }
                case BeaconMessageType.Acknowledge: {
                    const response = {
                        type: message.type,
                        version: '2',
                        senderId,
                        id: message.id
                    };
                    interceptorCallback(response);
                    break;
                }
                case BeaconMessageType.PermissionResponse: {
                    const response = Object.assign({ senderId, version: '2', appMetadata: ownAppMetadata }, message);
                    const publicKey = response.publicKey;
                    const address = yield getAddressFromPublicKey(publicKey);
                    const appMetadata = yield appMetadataManager.getAppMetadata(request.senderId);
                    if (!appMetadata) {
                        throw new Error('AppMetadata not found');
                    }
                    const permission = {
                        accountIdentifier: yield getAccountIdentifier(address, response.network),
                        senderId: request.senderId,
                        appMetadata,
                        website: '',
                        address,
                        publicKey,
                        network: response.network,
                        scopes: response.scopes,
                        connectedAt: new Date().getTime()
                    };
                    permissionManager.addPermission(permission).catch(console.error);
                    interceptorCallback(response);
                    break;
                }
                case BeaconMessageType.OperationResponse:
                    {
                        const response = Object.assign({ senderId, version: '2' }, message);
                        interceptorCallback(response);
                    }
                    break;
                case BeaconMessageType.SignPayloadResponse:
                    {
                        const response = Object.assign({ senderId, version: '2' }, message);
                        interceptorCallback(response);
                    }
                    break;
                // TODO: ENCRYPTION
                // case BeaconMessageType.EncryptPayloadResponse:
                //   {
                //     const response: EncryptPayloadResponse = {
                //       senderId,
                //       version: BEACON_VERSION,
                //       ...message
                //     }
                //     interceptorCallback(response)
                //   }
                //   break
                case BeaconMessageType.BroadcastResponse:
                    {
                        const response = Object.assign({ senderId, version: '2' }, message);
                        interceptorCallback(response);
                    }
                    break;
                default:
                    logger.log('intercept', 'Message not handled');
                    assertNever(message);
            }
        });
    }
}
function assertNever(_message) {
    throw new Error('Function not implemented.');
}
